exports.components = {
  "component---src-components-pages-about-js": () => import("./../../../src/components/pages/about.js" /* webpackChunkName: "component---src-components-pages-about-js" */),
  "component---src-components-pages-category-js": () => import("./../../../src/components/pages/category.js" /* webpackChunkName: "component---src-components-pages-category-js" */),
  "component---src-components-pages-contact-us-js": () => import("./../../../src/components/pages/contact-us.js" /* webpackChunkName: "component---src-components-pages-contact-us-js" */),
  "component---src-components-pages-home-js": () => import("./../../../src/components/pages/home.js" /* webpackChunkName: "component---src-components-pages-home-js" */),
  "component---src-components-pages-manuals-js": () => import("./../../../src/components/pages/manuals.js" /* webpackChunkName: "component---src-components-pages-manuals-js" */),
  "component---src-components-pages-product-js": () => import("./../../../src/components/pages/product.js" /* webpackChunkName: "component---src-components-pages-product-js" */),
  "component---src-components-pages-recipe-js": () => import("./../../../src/components/pages/recipe.js" /* webpackChunkName: "component---src-components-pages-recipe-js" */),
  "component---src-components-pages-recipes-js": () => import("./../../../src/components/pages/recipes.js" /* webpackChunkName: "component---src-components-pages-recipes-js" */),
  "component---src-components-pages-search-results-js": () => import("./../../../src/components/pages/search-results.js" /* webpackChunkName: "component---src-components-pages-search-results-js" */),
  "component---src-components-pages-service-centers-js": () => import("./../../../src/components/pages/service-centers.js" /* webpackChunkName: "component---src-components-pages-service-centers-js" */),
  "component---src-components-pages-simple-js": () => import("./../../../src/components/pages/simple.js" /* webpackChunkName: "component---src-components-pages-simple-js" */),
  "component---src-components-pages-terms-of-use-js": () => import("./../../../src/components/pages/terms-of-use.js" /* webpackChunkName: "component---src-components-pages-terms-of-use-js" */),
  "component---src-components-pages-where-to-buy-js": () => import("./../../../src/components/pages/where-to-buy.js" /* webpackChunkName: "component---src-components-pages-where-to-buy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

